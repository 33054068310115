.custom-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
  width: 50%;
  margin: auto;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input,
.input-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.submit-btn{
  text-align: center;
}
.submit-btn button {
  background-color: #6A5ACD;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn button:hover {
  background-color: #483D8B;
}

.location-icon {
  margin-left: 200px;
}

.social-links {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.social-links a {
  text-decoration: none;
}

.social-icon {
  font-size: 30px;
  margin-right: 10px;
  color: #000;
}

.social-icon:hover {
  color: #6A5ACD;
}



@import url('https://fonts.googleapis.com/css2?family=Artifika&display=swap');


.abc {
    font-family: "Artifika", serif;
    font-weight: 400;
    font-style: normal;
}