/* Base styles */
.kera-koll-container {
    width: 100%; /* Change to percentage for responsiveness */
    max-width: 800px; /* Add max-width for larger screens */
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.img-container {
    text-align: center;
}

.kera-koll-image {
    width: 45%; /* Adjusted for responsiveness */
    max-width: 150px; /* Set max-width to prevent image distortion */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px;
    margin-bottom: 20px;
    margin: auto;
}

.kera-koll-heading {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
}

.kera-koll-description {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 20px;
}

.kera-koll-features {
    list-style-type: none;
    padding-left: 0;
}

.kera-koll-features li {
    font-size: 1.1rem;
    color: #444;
    margin-bottom: 8px;
}

/* Media Queries for responsiveness */
@media screen and (max-width: 768px) {
    .kera-koll-heading {
        font-size: 2rem; /* Adjust font size for smaller screens */
    }
}

@media screen and (max-width: 576px) {
    .kera-koll-container {
        padding: 15px; /* Adjust padding for smaller screens */
    }

    .kera-koll-heading {
        font-size: 1.8rem; /* Adjust font size for smaller screens */
    }

    .kera-koll-description {
        font-size: 1rem; /* Adjust font size for smaller screens */
    }
}



.main {
  font-family: 'Arial, sans-serif';
  margin: 0 auto;
  height: 100%;
  padding: 20px;
  background-image: url('https://t3.ftcdn.net/jpg/06/95/50/36/240_F_695503690_wtVoNmrNciWP3RjcRftHf8evFpXojQhB.jpg');
  border-radius: 8px;
  background-size: cover;
  color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; /* Ensure wrapping on smaller screens */
}

.logo {
  width: 100px;
  height: auto;
}

.contact {
  text-align: right;
  flex: 1; /* Allow it to take available space */
  min-width: 150px; /* Ensure it doesn't get too small */
}

.name {
  font-size: 24px;
  font-weight: bold;
}

.phone {
  font-size: 18px;
  /* color: #555; */
}

.slogan {
  text-align: center;
  font-size: 20px;
  font-style: italic;
  margin: 20px 0;
}

.distributors {
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
}

.line {
  border: none;
  height: 2px;
  background-color: #ddd;
  margin: 20px 0;
}

.brands {
  display: flex;
  flex-direction: column; /* Stack brands vertically */
  align-items: center;
  margin: 20px 0;
}

.brand {
  width: 100px;
  height: auto;
  margin: 5px 0; /* Add margin between brand items */
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.location, .email {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.icon {
  width: 24px;
  height: auto;
  margin-right: 10px;
}

.address, .email span {
  font-size: 16px;
  /* color: #333; */
}

/* Media query for tablets */
@media (min-width: 600px) {
  .brands {
    flex-direction: row; /* Arrange brands in a row */
    justify-content: space-around;
  }
}

/* Media query for desktops */
@media (min-width: 1024px) {
  .head {
    flex-wrap: nowrap; /* Prevent wrapping */
  }

  .contact {
    text-align: right;
    flex: none; /* Prevent it from taking available space */
  }

  .brands {
    justify-content: space-around;
  }
}
