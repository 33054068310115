.product1 {
    /* border: 1px solid black; */
    /* column-gap: 2px; */
    width: 450px;
    padding-left: 10px;
}

.title1 {
    /* width: 150px; */
    color: rgb(9, 9, 156);
    font-weight: bold;
    border-bottom: 3px solid rgb(237, 38, 38);
    background-color:white;
}
.img1{
    padding-left: 100px;
}
.product-item1{
    display: flex;
    /* border-bottom: 1px solid black; */
    column-gap: 2px;
}
.main1{
    display: flex;
    flex-wrap: wrap;
}

.pdf-viewer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Set height to fill the entire viewport */
  }
  
  .pdf-document-container {
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
  }
  
  .pdf-page {
    margin: 20px 0; /* Add spacing between pages */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Add shadow effect to pages */
  }
  
  .product1{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }