.Landing_main{
    background: linear-gradient(to right,rgb(241, 241, 245),rgb(236, 232, 236));
    color: black;
    width: 100%;
}
.Categories_main_card{
    height: 380px;
    border: 2px solid blueviolet;
    width: 250px;
    text-align: center;
    margin-left: 40px;
}
.Categories_main_card img{
    height: 200px;
    width: 180px;
}
.Categories_main_card Button{
    border: 2px solid blueviolet;
}


/* Screed css */

.screed_div:hover{
    background: linear-gradient(red,blue)
    
}
.screed_first{
    background: linear-gradient(red,blue)

}

/* Printing */
@import url('https://fonts.googleapis.com/css2?family=Marko+One&display=swap');
.legend{
    font-size: x-large;
    font-family: "Marko One", serif;
    font-style: normal;
    font-weight: 400;
}
.legend-head{
    font-family: "Marko One", serif;
    color: black;
    font-style: normal;
    font-weight: 200;
    text-align: justify;
    background-color: #f2f2f2;
}
.img_printing{
    height: 300px;
}
.content-printing{
    display: flex;
    column-gap: 50px;

}
.advantages-details{
    padding: 20px;
}
.advantages-details ul{
    margin: auto;
    width: 300px;
}
.advantage-wrapper{
    display: flex;
    width: 100%;
    column-gap: 100px;
    /* padding: 20px; */
}

@media screen and (max-width: 768px) {
    .Categories_main_card{
        margin: auto;
    }
    .Landing_main{
        text-align: center;
    }
}
.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  
  .card {
    width: calc(50% - 10px);
    max-width: 600px;
    margin-bottom: 20px;
  }
 
@media (max-width: 768px) {
    .carousel-slide-flex div{
        display: block !important;
    }
}