.Footer_content{
    height: 30px;
    width: 100%;
    background: linear-gradient(to right,rgb(241, 241, 245),rgb(236, 232, 236));
    position: fixed;
    z-index: 99;
    bottom: 0;
}
.Footer_content h4{
    height: 20px;
    margin: auto;
    text-align: center;
}