/* Header.css */
*{
  margin: 0;
  padding: 0;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: linear-gradient(to right,rgb(241, 241, 245),rgb(236, 232, 236));
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: rgb(13, 12, 12);
    font-weight: 600;
    z-index: 1;
    height: 55px;
    margin: auto;
    position: fixed;
    width: 100vw;
    height: 50px;
  }
    .menu-toggle {
      cursor: pointer;
      display: none;
    }
  
    .nav {
      display: flex;
      justify-content: flex-end;
      z-index: 1; /* Set the z-index value to control the stacking order */
    }
    
    .nav ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
    }
    
    .nav li {
      margin: 0 25px;
      text-decoration: none;
      font-size: 200;
    }
    .nav ul li a{
      text-decoration: none;
      color: black;
    }
    .header_ul li a:hover {
    color: blueviolet;

    }
    @media (max-width: 768px) {
      .logo{
        margin-left: 25px;
      }
      .header{
        margin-left: -40px;
      }
      .menu-toggle {
        display: block;
      }
    
      .nav {
        display: none;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        flex-direction: column;
      }
  
      .nav ul {
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column; 
          text-align: center;
          background: linear-gradient(to right,rgb(241, 241, 245),rgb(236, 232, 236));
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
      .nav.active {
        display: flex;
      }
    
      .nav li {
        margin: 15px 0;
      }
    }
  